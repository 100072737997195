.grid {
    justify-content: center;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 30px;
  }
  
  .column {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    line-height: normal;
    width: 100%;
    margin-left: 0px;
    grid-row-gap: 0px;
    grid-column-gap: 40px;
  }

  @media (max-width: 1250px) {
    .grid {
      
      padding: 20px;
    }
  
    
  }
  
  @media (max-width: 991px) {
    .grid {
      max-width: 100%;
      padding: 0 20px;
    }
  
    .column {
      width: 100%;
    }
  }

  @media(max-width: 900px){
    .column{
      grid-template-columns: repeat(1,1fr);
    }
  }

  @media (max-width: 600px) {
    .grid {
      max-width: 100%;
      padding: 10px;
      border-radius: 5px;
    }
  
    .column {
      grid-template-columns: repeat(1,1fr);
    }
  }
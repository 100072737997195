.div {
    align-self: stretch;
    padding: 0px 15px 40px;
  }
  
  .div2 {
    gap: 20px;
    display: flex;
  }
  
  @media (max-width: 991px) {
    .div2 {
      align-items: stretch;
      gap: 0px;
      gap: 20px;
      flex-direction: column;
    }
  }
  
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 76%;
    margin-left: 0px;
  }
  
  @media (max-width: 991px) {
    .column {
      width: 100%;
    }
  }
  
  .div3 {
    justify-content: flex-start;
    border-radius: 20px;
    
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: #2b2c2d;
    font-weight: 500;
    width: 100%;
    padding: 20px;
  }

  
  @media (max-width: 991px) {
    .div3 {
      max-width: 100%;
      margin-top: 20px;
    }
  }
  
  .div4 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 991px) {
    .div4 {
      max-width: 100%;
    }
  }
  
  .div5 {
    align-self: start;
    display: flex;
    gap: 10px;
    font-size: 24px;
    text-transform: uppercase;
  }
  
  .img {
    aspect-ratio: 1.45;
    object-fit: auto;
    object-position: center;
    width: 29px;
    fill: #2b2c2d;
  }
  
  .div6 {
    leading-trim: both;
    text-edge: cap;
    font-family: Georama, sans-serif;
  }
  
  .div7 {
    leading-trim: both;
    text-edge: cap;
    border-radius: 10px;
    background-color: #f7f8ff;
    margin-top: 20px;
    text-align: justify;
    justify-content: center;
    padding: 30px;
    font: 20px/30px Georama, sans-serif;
  }
  
  @media (max-width: 991px) {
    .div7 {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  .column2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
    margin-left: 20px;
  }
  
  @media (max-width: 991px) {
    .column2 {
      width: 100%;
    }
  }
  
  .div8 {
    align-self: stretch;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }
  
  @media (max-width: 991px) {
    .div8 {
      margin-top: 20px;
      align-items: center;
      gap: 10px;
    }
  }
  
  .div9 {
    border-radius: 10px;
    border-color: rgba(247, 248, 255, 1);
    border-style: solid;
    border-width: 4px;
    background-color: #f7f8ff;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #2b2c2d;
    font-weight: 500;
    justify-content: center;
    padding: 10px;
  }
  
  .div10 {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .img2 {
    object-fit: cover;
    width: 111px;
    max-width: 100%;
  }
  
  .div11 {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  .div12 {
    leading-trim: both;
    text-edge: cap;
    text-transform: uppercase;
    font: 24px Georama, sans-serif;
    font-weight: 500;
  }
  
  .div13 {
    leading-trim: both;
    text-edge: cap;
    margin-top: 20px;
    font: 20px/30px Georama, sans-serif;
  }
  
  .div14 {
    justify-content: center;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .img3 {
    aspect-ratio: 2.56;
    object-fit: cover !important;
    object-position: center;
    width: 100%;
  }

  .remarkSpan{
    border-bottom: 1px dotted rgb(43, 44, 45);
    cursor: pointer;
  }

  @media(max-width: 1600px){
     .div5{
        font-size: 20px;
     }

     .div7{
        font-size: 18px;
     }

     .div12{
        font-size: 20px;
     }

     .div13{
        font-size: 18px;
     }

     .column{
        width: 71%;
     }

    

     .column2{
        width: 29%;
     }

     @media (max-width: 1350px) {
      .column {
        width: 65%;
      }

      .column2{
        width: 35%;
     }
    }

    

     .img2{
        width: 94px;
     }
  }

  @media(max-width: 1250px){
    .div5{
      font-size: 18px;
    }

    .div12{
      font-size: 18px;
    }

    .div7{
      font-size: 16px;
      margin-top: 10px;
      padding: 20px;
    }

    .div13{
      font-size: 16px;
      margin-top: 10px;
    }

    
  }

  @media(max-width: 1024px){
    .column2{
      margin-left: 0;
    }
  }
  
  @media (max-width: 991px) {
    .div14 {
      margin: 0 0px;
    }

    .column{
      width: 100%;
    }

    .column2{
      margin-left: 0;
      width: 100%;
    }

    .div10{
      justify-content: center;
    }

    .img3{
      width: 300px;
    }
  }
  
 

  @media (max-width: 600px){
    .div3{
      margin-top: 0;
      padding: 10px;
      border-radius: 5px;
    }

    .div{
      padding: 0 0 24px;
    }

    .column{
      width: 100%;
    }

    .div5{
      font-size: 12px;
      font-weight: 700;
    }

    .img{
      width: 11px;
    }

    .div7{
      margin-top: 10px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }

    .div8{
      padding: 10px;
      border-radius: 5px;
      margin-top: 0;
    }

    .column2{
      margin-left: 0;
      width: 100%;
    }

    .div9{
      border-radius: 3px;
      justify-content: center;
    }

    .img2{
      width: 48px;
      height: 44px;
    }

    .div12{
      font-size: 12px;
      font-weight: 700;
    }

    .div13{
      margin-top: 5px;
      font-size: 10px;
      font-weight: 400;
      line-height: 120%;
    }

    .div10{
      justify-content: center;
    }

    .div8{
      gap: 5px;
    }

    .img3{
      height: 63px;
      width: 180px;
      object-fit: contain;
    }
  }
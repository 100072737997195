.header {
  justify-content: space-between;
  /* box-shadow: 0 19px 19px 0 rgba(241, 242, 255, 0.85), 0 5px 11px 0 rgba(241, 242, 255, 0.98); */
  background-color: #fff;
  display: flex;
  padding: 24px 40px;
  width: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;


}

.logo {
  aspect-ratio: 4.35;
  object-fit: auto;
  object-position: center;
  width: 260px;
  max-width: 100%;
}

.navigation {
  display: flex;
  gap: 20px;
  align-items: center;
  border-radius: 20px;
  padding: 10px 20px;
}

/*side navbar*/

.sidebar {
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 221px 62px 0 rgba(0, 0, 0, 0), 0 141px 57px 0 rgba(0, 0, 0, 0.01), 0 80px 48px 0 rgba(0, 0, 0, 0.03), 0 35px 35px 0 rgba(0, 0, 0, 0.04), 0 9px 19px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #282828;
  font-weight: 700;
  line-height: 67%;
  padding: 20px 10px;
  position: fixed;
  right: 0;
  top: 0px;
  transition: all 0.3s;
  height: 100vh;
  width: 50vw;

  
}

.sidebarhide {
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 221px 62px 0 rgba(0, 0, 0, 0), 0 141px 57px 0 rgba(0, 0, 0, 0.01), 0 80px 48px 0 rgba(0, 0, 0, 0.03), 0 35px 35px 0 rgba(0, 0, 0, 0.04), 0 9px 19px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #282828;
  font-weight: 700;
  line-height: 67%;
  padding: 20px 10px;
  position: fixed;
  right: 0;
  top: 0px;
  transition: all 0.3s;
  height: 100vh;
  width: 50vw;
  transform: translateX(50vw);
}


.closeIcon {
  aspect-ratio: 1.49;
  object-fit: auto;
  object-position: center;
  width: 18px;
  fill: var(--font, #2b2c2d);
  align-self: flex-end;
  margin-top: 4px;
  margin-right: 10px;
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 30px 0 0;
  width: 100%;
}

.navItem {
  font-family: Georama, sans-serif;
  margin-top: 20px;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
  color: #282828;
}

.active {
  background-color: var(--green, #00c90a);
  color: #fff;
}



@media (max-width: 1250px) {
  .logo{
    width: 220px;
  }
}

@media(max-width: 1024px){
  .navigation{
    display: none;
  }

  .burgerIcon{
    cursor: pointer;
    width: 24px;
  }
  
  .closeIcon{
    width: 24px;
    cursor: pointer;
  }
  
  .navList{
    font-size: 16px;
  }

  .navList a{
    padding: 15px
  }
}

@media (max-width: 991px) {
  .header {
    padding: 24px 20px;
    flex-wrap: wrap;
  }

  .navigation {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

@media(max-width: 600px){
  

  .logo{
    width: 108px;
    height: 25px;
  }

  .header{
    margin-top: -1px;
    width: 100%;
  }

  .header{
    margin-left: 0;
    margin-right: 0;
  }


  .burgerIcon, .closeIcon{
    width: 18px;
  }

  .navItem{
    padding: 10px;
    margin-top: 15px;
  }

  .navList a{
    padding: 0;
  }

  .navList{

    font-size: 14px;
  }
  
}
.container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0 15px 50px;
  }
  
  .title {
    color: #2b2c2d;
    width: 100%;
    font: 700 28px/74% Georama, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .content {
    align-content: flex-start;
    flex-wrap: wrap;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  
  .columns {
    display: flex;
    gap: 20px;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 33%;
  }
  
  .columnContent {
    border-radius: 10px;
    background-color: #f7f8ff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 20px;
    color: #2b2c2d;
    font-weight: 500;
    line-height: 150%;
    width: 100%;
    padding: 20px;
  }
  
  .columnTitle {
    text-transform: uppercase;
    font: 24px Georama, sans-serif;
    margin-bottom: 18px;
    font-weight: 500;
  }
  
  .ratingItem,
  .conditionItem {
    font-family: Georama, sans-serif;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    margin-top: 17px;
    padding: 10px 20px;
  }
  
  .colorIndicator {
    border-radius: 1000px;
    width: 25px;
    height: 25px;
  }

  @media(max-width: 1600px){
    .title{
        font-size: 24px;
    }

    .columnTitle{
        font-size: 22px;
        
    }
  }

  @media(max-width: 1250px){
    .title{
        font-size: 20px;
    }

    .columnTitle{
        font-size: 18px;
        margin-bottom: 0;
        
    }

    .columnContent{
      font-size: 16px;
    }

    .colorIndicator{
      width: 20px;
      height: 20px;
    }
  }
  
  @media (max-width: 991px) {
    .title,
    .content,
    .columnContent,
    .ratingItem,
    .conditionItem {
      max-width: 100%;
    }
  
    .columns {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  
    .column {
      width: 100%;
      margin-top: 20px;
    }
  
    .ratingItem,
    .conditionItem {
      flex-wrap: wrap;
      white-space: initial;
    }
  }

  @media(max-width: 900px){
    .title{
      font-size: 18px;
    }

    .column{
      margin-top: 0;
    }

    .columnTitle{
      font-size: 16px;
    }

    .columnContent{
      font-size: 14px;
    }
  }

  @media(max-width: 600px){
    .container{
      padding: 0 0 24px;
    }

    .title{
      font-size: 12px;
      margin-bottom: 10px;
    }

    .content{
      margin-top: 0;
      padding: 10px;
      border-radius: 5px;
    }

    .column{
      margin-top: 0;
    }

    .columnContent{
      padding: 5px;
      gap: 5px;
      line-height: normal;
    }

    .columnTitle{
      font-size: 10px;
      margin-bottom: 5px;
      font-weight: 700;
    }

    .ratingItem{
      margin-top: 0;
      padding: 5px;
      font-size: 10px;
      font-weight: 400;
      border-radius: 1px;
    }

    .columns{
      flex-direction: row;
      border-radius: 3px;
      gap: 5px;
    }

    .conditionItem{
      padding: 5px;
      margin-top: 0;
      border-radius: 1px;
    }

    .conditionItem span{
      font-size: 10px;
      font-weight: 400;
    }

    .colorIndicator{
      width: 8px;
      height: 8px;
    }

    
  }
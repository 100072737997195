.disclaimerContent {
    border-radius: 20px;
    background-color: #fff;
    margin-top: 20px;
    width: 100%;
    color: #4f4f4f;
    padding: 20px;
    font: 500 20px/30px Georama, -apple-system, Roboto, Helvetica, sans-serif;
  }

  @media(max-width: 1600px){
    .disclaimerContent{
    font-size: 18px;
    }
  }

  @media(max-width: 1250px){
    .disclaimerContent{
    font-size: 16px;
    }
  }
  
  @media (max-width: 991px) {
    .disclaimerContent {
      max-width: 100%;
    }
  }

  @media (max-width: 900px) {
    .disclaimerContent {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    .disclaimerContent {
      margin-top: 0;
      padding: 10px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
  }
.container {
  display: flex;
  gap: 20px;
  padding: 0px 15px 40px;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.mainImage iframe,
.mainImage video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 44%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .leftColumn {
    width: 100%;
  }
}

.reportContainer {
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

@media (max-width: 991px) {
  .reportContainer {
    max-width: 100%;
    margin-top: 30px;
  }
}

.imageContainer {
  justify-content: center;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

.requestDiv{
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  background-color: white;
}

@media (max-width: 991px) {
  .imageContainer {
    max-width: 100%;
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: 374px;
  padding: 10px 20px;
}

@media (max-width: 991px) {
  .imageWrapper {
    max-width: 100%;
  }
}

.mainImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.overlayContent {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}

.overlayHide {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: none;
}

@media (max-width: 991px) {
  .overlayContent {
    max-width: 100%;
    padding-left: 20px;
  }
}

/* .logo {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 84px;
  align-self: center;
  margin-top: 55px;
  display: none;
} */

.logo {
  width: 84px;
  object-fit: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* .logoHide {
  width: 84px;
  object-fit: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.imageContainer:hover .logoHide{
  display: block;
} */

@media (max-width: 991px) {
  .logo {
    margin-top: 40px;
  }
}

.statusIndicator {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #00c90a;
  align-self: flex-end;
  display: flex;
  /* margin-top: 220px; */
  width: 60px;
  transition: all 0.3s;
  position: absolute;
  bottom: 40px;
  right: 40px;
  height: 40px;
}

.statusIndicator:hover{
  width: 65px;
}

@media (max-width: 991px) {
  .statusIndicator {
    margin-top: 40px;
    padding: 0 20px;
    height: 40px;
  }
}

.statusIcon {
  object-fit: auto;
  object-position: center;
  width: 55px;
  fill: #fff;
  cursor: pointer;
}

.actionButtons {
  display: flex;
  margin-top: 45px;
  gap: 20px;
  font-size: 28px;
  color: #fff;
  font-weight: 700;
  line-height: 74%;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .actionButtons {
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.actionButton {
  justify-content: center;
  border-radius: 10px;
  background-color: #00c90a;
  display: flex;
  gap: 20px;
  flex: 1;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}

.actionButton:hover{
  color: #00c90a !important;
  background-color: white;
  border: 1px solid #00c90a;

}

.actionButton:hover .actionText{
  color: #00c90a;
}

.actionIcon {
  aspect-ratio: 0.83;
  object-fit: auto;
  object-position: center;
  width: 25px;
  fill: #fff;
}

.actionText {
  font-family: Georama, sans-serif;
  margin: auto 0;
  color: #fff;
  font-family: Georama;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 74%; /* 20.72px */
  transition: all 0.4s;
}

.reportGrid {
  /* justify-content: space-between; */
  align-content: center;
  row-gap: 20px;
  flex-wrap: wrap;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  margin-top: 45px;
  flex-direction: column;
  padding: 20px;
}

@media (max-width: 991px) {
  .reportGrid {
    max-width: 100%;
    margin-top: 40px;
  }
}

.reportRow {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 20px;
  width: 100%;
}

.detailsSelect{
  width: 200px;
  font-size: 20px;
}

.detailsInput{
  width: 200px;
  font-size: 20px;
}

.detailsDate{
  width: 200px;
  font-size: 20px !important;
}

.vahanSpan{
  border-bottom: 1px dotted #2b2b2b;
  cursor: pointer;
}

.editButton{
  background-color: #00c90a;
  padding: 8px;
  width: 50px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  border: none;
  z-index: 9999;
  border-radius: 5px;
  text-align: center;
}

.imageWrapper:hover .editButton{
  display: block;
}

@media (max-width: 1400px) {
  .reportRow {
    max-width: 100%;
    grid-template-columns: repeat(1,1fr);
  }
}

@media (max-width: 991px) {
  .reportRow {
    max-width: 100%;
  }
}

.reportItem {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .reportItem {
    width: 100%;
  }
}

.reportContent {
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f7f8ff;
  display: flex;
  flex-grow: 1;
  gap: 20px;
  font-size: 20px;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
}

@media (max-width: 991px) {
  .reportContent {
    margin-top: 40px;
    padding: 0 20px;
  }
}

.reportDetails {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.reportHeader {
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  color: #2b2c2d;
  font-weight: 600;
  /* white-space: nowrap; */
  line-height: 110%;
  align-items: flex-start
}

@media (max-width: 991px) {
  .reportHeader {
    white-space: initial;
  }
}

.reportIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  fill: #2b2c2d;
}

.reportTitle {
  font-family: Georama, sans-serif;
  margin: auto 0;
  width: 120px;
  word-wrap: break-word;
  margin-top: -10px;
  color: #2B2C2D;
}

.reportScore {
  justify-content: flex-start;
  border-radius: 10px;
  display: flex;
  margin-top: 20px;
  gap: 5px;
}

.scoreValue {
  font-family: Georama, sans-serif;
  justify-content: center;
  border-radius: 5px;
  background-color: #00c90a;
  color: #fff;
  font-weight: 700;
  white-space: nowrap;
  line-height: 175%;
  padding: 5px;
}

@media (max-width: 991px) {
  .scoreValue {
    white-space: initial;
  }
}

.scoreText {
  color: #2b2c2d;
  font-family: Georama, sans-serif;
  font-weight: 500;
  line-height: 150%;
  margin: auto 0;
}

.reportImage {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 80px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 56%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .rightColumn {
    width: 100%;
  }
}

.detailsContainer {
  display: flex;
  /* flex-grow: 1; */
  flex-direction: column;
  color: #2b2c2d;
  font-weight: 500;
}

@media (max-width: 991px) {
  .detailsContainer {
    max-width: 100%;
    margin-top: 30px;
  }
}

.requesterInfo {
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

@media (max-width: 991px) {
  .requesterInfo {
    max-width: 100%;
  }
}

.requesterHeader {
  align-self: flex-start;
  display: flex;
  gap: 10px;
  font-size: 24px;
  text-transform: uppercase;
}

.requesterIcon {
  aspect-ratio: 0.95;
  object-fit: auto;
  object-position: center;
  width: 19px;
  fill: #2b2c2d;
}

.requesterTitle {
  font-family: Georama, sans-serif;
}

.requesterName {
  justify-content: center;
  border-radius: 10px;
  background-color: #f7f8ff;
  margin-top: 20px;
  white-space: nowrap;
  text-align: justify;
  padding: 20px 10px;
  font: 20px/150% Georama, sans-serif;
}

@media (max-width: 991px) {
  .requesterName {
    max-width: 100%;
    white-space: initial;
  }
}

.vehicleDetails {
  justify-content: center;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  padding: 20px;
  gap: 20px;
}

@media (max-width: 991px) {
  .vehicleDetails {
    max-width: 100%;
  }
}

.detailItem {
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f7f8ff;
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 20px 10px;
}

@media (max-width: 991px) {
  .detailItem {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.detailLabel {
  justify-content: center;
  display: flex;
  gap: 10px;
}

.detailIcon {
  aspect-ratio: 0.85;
  object-fit: auto;
  object-position: center;
  width: 17px;
  fill: #2b2c2d;
}

.detailText {
  font-family: Georama, sans-serif;
}

.detailValue {
  font-family: Georama, sans-serif;
  margin: auto 0;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ratingCircle{
  width: 80px;
  height: 80px;
}


@media(max-width: 1600px){
  .requesterHeader{
    font-size: 22px;
  }

  .requesterName{
    font-size: 18px;
  }

  .vehicleDetails{
    font-size: 18px;
  }

  .actionText{
    font-size: 22px;
    line-height: 84%;
  }

  .ratingCircle{
    width: 50px;
    height: 50px;
  }

  .reportContent{
    font-size: 18px;
  }

  .reportScore{
    margin-top: 15px;
  }

  .leftColumn{
    width: 45%;
  }

  /* .imageContainer{
    height: 350px;
  }

  .imageWrapper{
    min-height: 310px;
  } */
  
}

@media(max-width: 1250px){
  .imageWrapper {
    min-height: 270px;
  }

  .actionButtons{
    margin-top: 25px;
  }

  .reportGrid{
    margin-top: 25px;
  }

  .actionText{
    font-size: 18px !important;
  }

  .vehicleDetails{
    font-size: 16px;
  }

  .reportContent{
    font-size: 16px;
  }
}

@media(max-width: 991px){
  .reportContainer{
    margin-top: 15px;
  }

  .leftColumn{
    width: 100%;
  }

  .imageWrapper{
    min-height: 390px;
  }

  .actionButtons{
    padding: 0;
  }

  .reportRow{
    grid-template-columns: repeat(2,1fr);
  }

  .reportContent{
    margin-top: 0;
    height: 115px;
  }

  .rightColumn{
    margin-left: 0;
  }

  .requesterHeader{
    font-size: 20px;
  }

  .container{
    padding: 0 15px 20px;
  }
}

@media(max-width: 800px){
  .actionText{
    font-size: 16px !important;
  }

  .actionIcon{
    width: 16px;
  }

  .hoveredIcon{
    width: 16px;
  }

  .requesterHeader{
    font-size: 18px;
  }

  .requesterIcon{
    width: 16px;
  }

  .reportContainer{
    margin-top: 0;
  }
}

@media(max-width: 600px){
  .reportContainer{
    margin-top: 24px;
    width: 100%;
    
  }

  .detailsSelect,.detailsInput,.detailsDate{
    padding: 5px;
    font-size: 12px;
  }

  .leftColumn{
    width: 100%;
  }

  .container{
    padding: 0 0 13px;
  }

  .imageContainer{
    padding: 10px;
    height: 300px;
    border-radius: 5px;
    position: relative;
  }

  .imageWrapper{
    min-height: 300px;
    border-radius: 2px;
    position: relative;
  }

  .logo{
    width: 28px;
    height: 28px;
    margin-top: 0;
  }

  .statusIndicator{
    width: 20px !important;
    height: 35px;
  }

  .actionButtons{
    margin-top: 10px;
    flex-direction: row;
    padding: 0;
  }

  .actionButton{
    width: 50%;
    font-size: 12px;
    gap: 5px;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    
  }

  .actionText{
    font-size: 12px !important;
  }

  .actionIcon{
    width: 10px;
  }

  .hoveredIcon{
    width: 10px;
  }

  .reportGrid{
    margin-top: 24px;
    padding: 10px;
    border-radius: 5px;
  }

  .reportContent{
    margin-top: 0;
    padding: 10px 5px;
    gap: 5px;
  }

  .reportIcon{
    width: 8px;
    height: 8px;
  }

  .reportTitle{
    font-size: 10px;
    margin-top: -6px;
  }

  .reportHeader{
    gap: 5px;
  }

  .reportScore{
    margin-top: 10px;
  }

  .scoreValue{
    font-size: 10px;
    font-weight: 400;
  }

  .scoreText{
    font-size: 12px;
    font-weight: 400;
  }

  .ratingCircle{
    width: 30px;
    height: 30px;
  }

  .reportTitle{
    width: 97px;
  }

  .reportRow{
    gap: 10px;
  }

  .detailsContainer{
    margin-top: 24px;
  }

  .rightColumn{
    margin-left: 0;
  }

  .requesterInfo{
    border-radius: 5px;
    padding: 10px;
  }

  .requesterHeader{
    font-size: 12px;
    font-weight: 700;
  }

  .requesterIcon{
    width: 8px;
  }

  .requesterName{
    margin-top: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
  }

  .vehicleDetails{
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
  }

  .detailItem{
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
  }

  .detailIcon{
    width: 8px;
  }

  .statusIndicator{
    position: absolute;
    bottom: 19px;
    right: 20px;
  }

  .reportContent{
    height: auto;
  }

  .detailsSelect{
    width: auto;
  }

}

@media(max-width: 400px){
  .imageWrapper{
    min-height: 200px;
  }

  .imageContainer{
    height: 200px;
  }
}

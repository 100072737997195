.disclaimerSection {
    display: flex;
    flex-direction: column;
    padding: 0 15px 346px;
  }
  
  .disclaimerTitle {
    color: #2b2c2d;
    width: 100%;
    font: 700 28px/74% Georama, -apple-system, Roboto, Helvetica, sans-serif;
  }

  @media(max-width: 1600px){
    .disclaimerTitle{
        font-size: 24px;
    }
  }

  @media(max-width: 1250px){
    .disclaimerTitle{
        font-size: 20px;
    }
  }
  
  @media (max-width: 991px) {
    .disclaimerTitle {
      max-width: 100%;
    }
  }

  @media (max-width: 900px) {
    .disclaimerTitle {
      font-size: 18px;
    }

    .disclaimerSection{
      padding: 0 15px 240px;
    }
  }

  @media (max-width: 600px) {
    .disclaimerTitle {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .disclaimerSection{
      padding: 0 0 33px;
    }
  }
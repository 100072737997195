.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
  width: 100%;
  height: 100%;
  row-gap: 5em;
}

.reportContainer {
  border: 4px solid #c7c7c7;
  width: 98%;
  display: flex;
  flex-direction: column;
}

.reportContainer .reportHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-bottom: 4px solid #c7c7c7;
}

.reportContainer .reportHeader img {
  width: 5em;
  object-fit: scale-down;
}

.reportContainer .reportHeader-btn {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 0.5em;
}

.reportContainer .reportHeader-btn h1 {
  margin: 0;
  font-size: 1.125em;
  font-style: normal;
}

.reportContainer .reportHeader-btn a {
  text-align: center;
  font-size: 1.25em;
  font-style: normal;
  background: red;
  color: #fff;
  padding: 0.4em 4em;
  border-radius: 10px;
  text-decoration: none;
}

.reportContainer .container-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  padding: 1em;
}

.reportContainer .container-1 h1 {
  margin: 0 auto;
  font-weight: 700;
  font-size: 1.625em;
}

.reportContainer .container-1 .sub-container-1 {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.reportContainer .container-1 .sub-container-1 table {
  font-size: 0.75em;
  border-collapse: collapse;
  border-color: #c7c7c7;
  width: 100%;
  height: 25em;
}

.reportContainer .container-1 .sub-container-1 table th {
  background: #09a541;
  color: #fff;
  font-weight: 600;
  color: #ffffff;
  padding: 8px;
  padding-left: 1.25em;
  text-align: left;
  font-size: 1em;
}

.reportContainer .container-1 .sub-container-1 table td {
  padding-left: 1.25em;
  line-height: 2;
  font-weight: 400;
  white-space: nowrap;
}

.reportContainer .container-1 .sub-container-1 table td:nth-child(1) {
  font-size: 1em;
}

.reportContainer .container-1 .sub-container-1 table td:nth-child(2) {
  font-size: 1.125em;
}

.reportContainer .container-1 .sub-container-1 section {
  border: 1px solid #c7c7c7;
  width: 100%;
  height: 37.5em;
  display: flex;
  flex-direction: column;
}

.reportContainer .container-1 .sub-container-1 section .head {
  background-color: #f2ad00;
  text-align: center;
  padding: 0.625em;
  white-space: nowrap;
}

.reportContainer .container-1 .sub-container-1 section .head h1 {
  font-weight: 700;
  font-size: 1.438em;
  color: #ffffff;
}

.reportContainer .container-1 .sub-container-1 section .tail {
  display: flex;
  padding: 0.5em;
  height: 91%;
}

.reportContainer .container-1 .sub-container-1 section .tail img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.reportContainer .container-1 .info-cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1em;
  column-gap: 1em;
  list-style: none;
  margin: auto;
  justify-content: center;
  padding: 0;
}

.reportContainer .container-1 .info-cards li {
  border: 2px solid #d9d9d9;
  padding: 0.438em 1.25em;
  border-radius: 10px;
  min-width: 25em;
  width: 24%;
}

.reportContainer .container-1 .info-cards li h1 {
  margin: 0;
  font-size: 1.125em;
  font-weight: 700;
  color: #000000;
  font-style: normal;
}

.reportContainer .container-1 .info-cards li p {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  color: #757575;
  line-height: 1.125em;
}

.reportContainer .container-1 .vehicle-price {
  background-color: #f4eaff;
  font-size: 1.125em;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  font-weight: bold;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
}

.reportContainer .container-1 .vehicle-price h2 {
  font-size: 2.5em;
}

.reportContainer .container-1 .vehicle-price .title {
  display: flex;
  column-gap: 1em;
  align-items: center;
  white-space: nowrap;
}

.reportContainer .container-1 .vehicle-price .title img {
  width: 3em;
  object-fit: scale-down;
}

.reportContainer .container-1 .vehicle-price .title h3 {
  font-size: 1.563em;
  font-weight: 600;
  margin: 0;
}

.reportContainer .container-1 .summery-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #c7c7c7;
  width: 100%;
}

.reportContainer .container-1 .summery-wrapper .head {
  background-color: #09a541;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0.5em;
}

.reportContainer .container-1 .summery-wrapper .head h4 {
  color: #fff;
  font-weight: 600;
  font-size: 1.4em;
  margin: 0;
}

.reportContainer .container-1 .summery-wrapper .head .score {
  display: flex;
  column-gap: 1.3em;
  align-items: center;
}

.reportContainer .container-1 .summery-wrapper .head .score .score-text {
  padding: 0.125em 0.5em;
  background-color: #f5ebff;
  border-radius: 4px;
}

.reportContainer .container-1 .summery-wrapper .head .score .score-text h5 {
  font-size: 1.125em;
  margin: 0;
  font-weight: 900;
}
.reportContainer
  .container-1
  .summery-wrapper
  .head
  .score
  .score-text
  h5
  span {
  font-size: 0.75em;
}

.reportContainer .container-1 .summery-wrapper .tail {
  display: flex;
  column-gap: 2em;
  padding: 1em;
}

.reportContainer .container-1 .summery-wrapper .tail .col {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.score-item {
  display: flex;
  flex-direction: column;
}

.score-item input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 0.75em;
  background: #cbc464;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
}

.score-item input[type='range']::-webkit-slider-thumb,
.score-item input[type='range']::-moz-range-thumb,
.score-item input[type='range']::-ms-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 31px;
  background: #adffb8;
  cursor: pointer;
}

.score-item .score-item-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.score-item .score-item-head h4 {
  font-size: 1.3em;
  font-weight: 400;
  margin: 0;
}

.score-item .score-item-head h3 {
  font-size: 1.3em;
  font-weight: 700;
  margin: 0;
}

.reportContainer .container-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  padding: 1em;
}

.reportContainer .container-2 .emarks {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.reportContainer .container-2 .emarks h3 {
  font-weight: 700;
  font-size: 1.75em;
  color: #09a541;
}

.reportContainer .container-2 .emarks .content {
  width: 100%;
  margin: auto;
  background-color: #eeeeee;
  padding: 1.25em 1.875em;
  border-radius: 10px;
}

.reportContainer .container-2 .emarks .content p {
  margin: 0;
  color: #000000;
  font-size: 1.375em;
}

.reportContainer .container-2 .table-details {
  font-size: 1em;
  margin: auto;
  border-collapse: collapse;
  border-color: #c7c7c7;
  width: 97%;
}

.reportContainer .container-2 .table-details th {
  background: #f2ad00;
  font-weight: bold;
  padding: 0.5em;
  border: 1px solid #c7c7c7;
  color: #000000;
  text-align: left;
  line-height: 1.125em;
}

.reportContainer .container-2 .table-details td {
  width: 49%;
  padding: 0.5em;
}

.reportContainer .container-2 .table-details .col {
  display: flex;
  align-items: center;
  column-gap: 1em;
  margin: 1em 0;
}

.reportContainer .container-2 .table-details .col p {
  font-size: 1.125em;
  margin: 0;
  color: #000000;
}

.reportContainer .images-list {
  list-style: none;
  padding: 0.5em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1em;
  justify-content: space-between;
}

.reportContainer .images-list li {
  border: 1px solid #c7c7c7;
  padding: 0.938em 1.25em 0.313em;
  width: 100%;
  height: 37.5em;
}

.reportContainer .images-list li img {
  width: 100%;
  height: 96%;
  object-fit: fill;
}

.reportContainer .images-list li p {
  margin: 0;
}

.page-wrapper {
  font-size: 10px;
}

@media (min-width: 800px) {
  .page-wrapper {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .page-wrapper {
    font-size: 16px;
  }
  .reportContainer .container-1 .sub-container-1 {
    flex-direction: row;
  }
  .reportContainer .container-1 .sub-container-1 table {
    width: 50%;
  }
  .reportContainer .container-1 .sub-container-1 section {
    width: 50%;
  }
  .reportContainer .container-1 .vehicle-price {
    justify-content: start;
    column-gap: 30%;
  }
  .reportContainer .container-1 .vehicle-price .title {
    padding-left: 5em;
  }

  .reportContainer .images-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

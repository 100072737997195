.item {
  justify-content: space-between;
  border-top: 2px solid #f7f8ff;
  border-bottom: 2px solid #f7f8ff;
  background-color: #fff;
  display: flex;
  gap: 18px;
  padding: 20px 10px;
  align-items: center;
}

.title {
  leading-trim: both;
  text-edge: cap;
  font-family: Georama, sans-serif;
  margin: auto 0;
  font-size: 20px;
  color: #2b2c2d;
  font-weight: 500;
  line-height: 150%;
}

.image {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 32px;
}

.ratingDiv {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.rating {
  color: #09a541;
  text-align: center;
  font-family: Georama;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  margin-bottom: 0;
}

@media(max-width: 1600px){
    .title{
        font-size: 18px;
    }

    .rating{
        font-size: 18px;
    }

    .image{
        width: 26px;
    }
}

@media(max-width: 1250px){
  .title {
    font-size: 16px;
  }

  .rating {
    font-size: 16px;
  }

  .image{
    width: 20px;
  }
}

@media (max-width: 991px) {
  .item {
    flex-wrap: wrap;
  }

  .title {
    max-width: 100%;
    width: 160px;
  }
}

@media (max-width: 900px) {


  .title {
    font-size: 14px;
  }

  .rating{
    font-size: 14px;
  }

  .image{
    width: 16px;
  }

  .item{
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .item {
    padding: 5px;
  }

  .title {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
  }

  .rating{
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 0;
  }

  .image{
    width: 12px;
  }

  .ratingDiv{
    gap: 5px;
  }
}

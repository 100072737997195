.interiorOuter {
  padding: 0 15px 50px;
}

.interiorOuter h2 {
  color: #2b2c2d;
  font-family: Georama;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 74%; /* 20.72px */
  margin-bottom: 20px;;
}

@media(max-width: 1600px){
    .interiorOuter h2{
        font-size: 24px;
    }
}

@media(max-width: 1250px){
  .interiorOuter h2{
      font-size: 20px;
  }
}

@media(max-width: 900px){
  .interiorOuter h2{
      font-size: 18px;
  }

  
}

@media(max-width: 600px){
  .interiorOuter h2{
      font-size: 12px;
      margin-bottom: 10px;
  }

  .interiorOuter{
    padding: 0 0 24px;
  }

  
}


.navItem {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    color: #3d3d3d;
    line-height: 130%;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .active {
    background-color: #00c90a;
    cursor: pointer;
    color: #fff;
  }

  @media (max-width: 1250px) {
    .navItem {
      font-size: 18px;
    }
  }
  
  @media (max-width: 991px) {
    .navItem {
      white-space: normal;
    }
  }
.row {
    justify-content: center;
    border-color: rgba(247, 248, 255, 1);
    border-style: solid;
    border-top-width: 3px;
    border-bottom-width: 3px;
    background-color: #fff;
    display: flex;
    gap: 16px;
    padding: 16px;
  }
  
  .label {
    font-family: Georama, sans-serif;
    flex: 1;
  }
  
  .value {
    font-family: Georama, sans-serif;
    flex: 1;
  }
  
  @media (max-width: 991px) {
    .row {
      flex-wrap: wrap;
    }
  
    .label,
    .value {
      max-width: 100%;
    }
  }

  @media (max-width: 480px){

    .row{
      padding: 5px;
    }

    .row{
      border: none;
      border-bottom: 2px solid #F7F8FF;
    }
  }
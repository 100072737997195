.container {
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    color: #2b2c2d;
    padding: 30px 20px;
    margin-top: 124px;
  }
  
  .outerContainer{
    padding: 30px 15px 0px;
  }
  
  .header {
    justify-content: space-between;
    display: flex;
    gap: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    align-items: center;
  }

  .vehicleData{
    width: 150px;
  }
  
  @media (max-width: 991px) {
    .header {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .title,
  .reportId,
  .loanNo {
    font-family: Georama, sans-serif;
  }

  .title{
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .title input{
    width: 70px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    
  }

  .loanNo{
    display: flex;
    width: 400px;
    justify-content: flex-end;
    align-items: center;
  }

  .loanNo p{
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color :#2B2C2D;
    margin-bottom: 0;
  }

  .loanNo input{
    width: 150px;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color :#2B2C2D;

  }

  .loanSpan, .vehSpan, .locSpan{
    border-bottom: 1px dotted #2b2c2d;
    cursor: pointer;
  }

  
  .vehicleDetails {
    justify-content: space-between;
    display: flex;
    margin-top: 40px;
    width: 100%;
    gap: 13px;
  }
  
  @media (max-width: 991px) {
    .vehicleDetails {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
  
  .model {
    font: 700 40px/89% Georama, -apple-system, Roboto, Helvetica, sans-serif;
  }


  
  @media (max-width: 991px) {
    .model {
      max-width: 100%;
      font-size: 40px;
    }
  }
  
  .location {
    align-self: start;
    display: flex;
    gap: 5px;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .locationIcon {
    aspect-ratio: 0.8;
    object-fit: auto;
    object-position: center;
    width: 16px;
    fill: #2b2c2d;
  }

  @media(max-width: 1600px){
    .model {
        font: 700 30px/89% Georama, -apple-system, Roboto, Helvetica, sans-serif;
      }

      .header {
        font-size: 18px;
      }

      .location {
        font-size: 20px;
      }
  }

  @media(max-width: 1600px){
    .header{
      font-size: 16px;
    }

    .model{
      font-size: 26px;
    }

    .vehicleDetails{
      margin-top: 20px;
    }

    .checkIcon{
      width: 11px;
      height: 11px;
    }
  }
  
  .locationName {
    font-family: Georama, sans-serif;
  }


  @media (max-width: 991px) {
    .container{
      margin-top: 95px;
    }

    .model{
      font-size: 22px;
    }

    .location{
      font-size: 18px;
    }

    .vehicleDetails{
      align-items: center;
    }

    
  }

  @media (max-width: 930px){
    .header{
      flex-direction: column;
      gap: 10px;
    }

    .vehicleDetails{
      gap: 20px;
    }

    .model{
      font-size: 18px;
    }

    .location{
      font-size: 16px;
    }

    .locationIcon{
      width: 12px;
    }

    .vehicleDetails{
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media(max-width: 600px){
    .container{
      margin-top: 62px;
      padding: 10px;
      border-radius: 5px;
    }

    .outerContainer{
      padding: 30px 0 0;
    }

    .title{
      font-size: 12px;
      font-weight: 400;
    }

    .header{
      gap:5px;
      font-size: 12px;
      font-weight: 400;
      flex-direction: column;
      align-items: flex-start;
    }

    .loanNo{
      justify-content: flex-start;
      
    }

    .vehicleData{
      width: 150px;
      padding: 5px;
      font-size: 12px;
    }

    .loanNo p{
      font-size: 12px;
    }

    .vehicleDetails{
      margin-top: 20px;
    }

    .model{
      font-size: 14px;
      line-height: normal;
    }

    .vehicleDetails{
      gap: 10px;
    }

    .location{
      font-size: 12px;
      font-weight: 400;
    }

    .locationIcon{
      width: 8px;
    }
  }
.container {
  justify-content: center;
  padding: 40px 15px;
}

.contentWrapper {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .contentWrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 39%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .leftColumn {
    width: 100%;
  }
}

.infoCard {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

@media (max-width: 991px) {
  .infoCard {
    max-width: 100%;
    margin-top: 20px;
  }
}

.valuationSection {
  justify-content: space-between;
  border-top-color: rgba(247, 248, 255, 1);
  border-top-style: solid;
  border-top-width: 2px;
  border-bottom-width: 2px;
  padding: 20px;
}

@media (max-width: 991px) {
  .valuationSection {
    max-width: 100%;
  }
}

.valuationContent {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .valuationContent {
    /* flex-direction: column; */
    align-items: stretch;
    gap: 0;
  }
}

.valuationPriceColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 75%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .valuationPriceColumn {
    width: 100%;
  }
}

.valuationPriceWrapper {
  justify-content: center;
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #2b2c2d;
  text-align: center;
}

@media (max-width: 991px) {
  .valuationPriceWrapper {
    margin-top: 0px;
  }

}

.valuationPriceLabel {
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.icon {
  aspect-ratio: 0.75;
  object-fit: auto;
  object-position: center;
  width: 15px;
  fill: #2b2c2d;
}

.labelText {
  font-family: Georama, sans-serif;
  margin: auto 0;
}

.valuationPrice {
  text-transform: uppercase;
  margin-top: 20px;
  font: 600 48px Georama, sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
}

.valuationPrice input{
  font: 600 48px Georama, sans-serif;
}


@media (max-width: 991px) {
  .valuationPrice {
    font-size: 40px;
  }
}

.carScoreColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .carScoreColumn {
    width: 100%;
  }
}

.carScoreWrapper {
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #2b2c2d;
  text-align: center;
}

@media (max-width: 991px) {
  .carScoreWrapper {
    margin-top: 0px;
  }
}

.carScoreLabel {
  justify-content: center;
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  width: 150px;
}

.carScoreIcon {
  aspect-ratio: 0.95;
  object-fit: auto;
  object-position: center;
  width: 19px;
}

.carScore {
  text-transform: uppercase;
  margin-top: 20px;
  font: 600 48px Georama, sans-serif;
}

@media (max-width: 991px) {
  .carScore {
    font-size: 40px;
  }
}

.inspectionLocation {
  background-color: #fff;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  color: #2b2c2d;
  font-weight: 500;
}

@media (max-width: 991px) {
  .inspectionLocation {
    max-width: 100%;
  }
}

.locationHeader {
  align-self: start;
  display: flex;
  gap: 10px;
  font-size: 24px;
  text-transform: uppercase;
}

.locationIcon {
  aspect-ratio: 0.8;
  object-fit: auto;
  object-position: center;
  width: 16px;
  fill: #2b2c2d;
}

.locationTitle {
  font-family: Georama, sans-serif;
}

.locationAddress {
  justify-content: center;
  border-radius: 10px;
  background-color: #f7f8ff;
  margin-top: 20px;
  text-align: justify;
  padding: 20px 10px;
  font: 20px/30px Georama, sans-serif;
  font-weight: 500;
}

@media (max-width: 991px) {
  .locationAddress {
    max-width: 100%;
  }
}

.vehicleDetails {
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f7f8ff;
  margin-top: 20px;
  padding: 20px 10px;
}

@media (max-width: 991px) {
  .vehicleDetails {
    max-width: 100%;
  }
}

.detailsContent {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .detailsContent {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.detailsColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
}

@media (max-width: 991px) {
  .detailsColumn {
    width: 100%;
  }
}

.detailsList {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 20px;
  color: #2b2c2d;
  font-weight: 500;
  line-height: 150%;
}

@media (max-width: 991px) {
  .detailsList {
    margin-top: 40px;
  }
}

.detailsItem {
  font-family: Georama, sans-serif;
}

.detailsValue {
  font-family: Georama, sans-serif;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.statusSection {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-right: 6px;
  gap: 20px;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 991px) {
  .statusSection {
    flex-wrap: wrap;
  }
}

.statusItem {
  font-family: Georama, sans-serif;
  justify-content: center;
  border-radius: 10px;
  background-color: #00c90a;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.imageInclude{
    position: relative;
}

.imageInclude:hover .overlayEdit{
  display: block;
  transition: 0.3s all;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.editButton{
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  width: 75px;
  color: white;
  text-align: center;
  font-size: 16px;
  background-color: #00c90a;
  font-weight: 500;
}

.detSpan{
  border-bottom: 1px dotted #2b2c2d;
  cursor: pointer;
}

.removeButton{
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 75px;
  color: white;
  text-align: center;
  font-size: 16px;
  background: red;
}

.overlayEdit{
  position: absolute;
  display: none;
  transition: 0.3s all;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@media (max-width: 1300px) {
  .statusSection {
    flex-direction: column;
  }

  .statusItem{
    height: auto !important;;
  }
}

@media (max-width: 1600px) {
  .statusSection {
    font-size: 18px;
  }

  .statusItem {
    height: 65px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .statusItem {
    white-space: initial;
  }
}

.damageStatus {
  justify-content: space-between;
  border-radius: 10px;
  background-color: #00c90a;
  display: flex;
  gap: 10px;
  font-size: 24px;
  line-height: 150%;
  padding: 10px 20px;
  font-weight: 500;
}

.damageIcon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
}

.damageText {
  font-family: Georama, sans-serif;
  margin: auto 0;
  text-align: center;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  /* width: 61%; */
  width: 59%;
  margin-left: 20px;
}


.rightSecondColumn {
  flex-grow: 1;
  background-color: white;
  width: 100%;
  border-radius: 20px;
}
@media (max-width: 1600px) {
  .rightColumn {
    width: 58%;
  }
}

@media (max-width: 991px) {
  .rightColumn {
    width: 100%;
  }
}

.imageGallery {
  justify-content: center;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  position: relative;
}

@media (max-width: 991px) {
  .imageGallery {
    max-width: 100%;
    margin-top: 20px;
  }
}

.imageCard {
  justify-content: center;
  border-radius: 10px;
  background-color: #f7f8ff;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.carImage {
  aspect-ratio: 1.79;
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-radius: 10px;
}

.imageInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.imageTitle {
  color: #2b2c2d;
  text-align: center;
  text-transform: uppercase;
  font: 700 24px Georama, sans-serif;
}

.approvalIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #00c90a;
  cursor: pointer;
}

.checkIcon {
  width: 44px;
  height: 36px;
  transition: all 0.3s;
}

.checkIcon:hover {
  width: 47px;
  height: 39px;
}

.imageMetadata {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 16px;
  color: #2b2c2d;
  font-weight: 500;
}

.metadataIcon {
  width: 15px;
  height: 15px;
}

.metadataText {
  font-family: Georama, sans-serif;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #00c90a;
}

.slick-dots li.slick-active button:before {
  color: #00c90a;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #00c90a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}

.sliderArrows {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
  right: 40px;
}

.leftArrow {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.rightArrow {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.itemRender{
  position: absolute;
  top: 50%;
}

@media (max-width: 1600px) {
  .valuationPriceLabel {
    font-size: 18px;
  }

  .sliderArrows {
    bottom: 20px;
  }

  .carScoreLabel {
    font-size: 18px;
  }

  .valuationPrice {
    font-size: 36px;
  }

  .valuationPrice input {
    font-size: 36px;
  }


  .carScore {
    font-size: 36px;
  }

  .valuationSection {
    padding: 10px;
  }

  .valuationPriceColumn {
    width: 58%;
  }

  .locationHeader {
    font-size: 22px;
  }

  .locationAddress {
    font-size: 18px;
  }

  .detailsList {
    font-size: 18px;
  }

  .damageStatus {
    font-size: 16px;
    line-height: 115%;
  }

  .imageTitle {
    font-size: 22px;
  }
}

@media (max-width: 1500px) {
  .sliderArrows {
    bottom: 20px;
  }
}

@media (max-width: 1300px) {
  .damageStatus{
    justify-content: center;
  }
}

@media(max-width: 1250px){
  .damageStatus {
    height: 40px;
    font-size: 18px;
    justify-content: center;
    }

    .statusItem{
      height: 40px;
    }

    .imageTitle {
      font-size: 18px;
    }
  
}

@media (max-width: 1250px) {

  
  .valuationPrice {
    font-size: 30px;
    margin-top: 10px;
  }

  .carScore{
    font-size: 30px;
    margin-top: 10px;
  }

  .valuationPriceLabel{
    font-size: 14px;
  }

  .carScoreLabel{
    font-size: 14px;
    width: 130px;
  }

  .icon{
    width: 10px;
  }

  .carScoreIcon{
    width: 10px;
  }

  .locationHeader{
    font-size: 18px;
  }

  .locationIcon{
    width: 10px;
  }

  .rightColumn{
    margin-left: 0;
    width: 61% !important;
  }

  .actionIcon{
    width: 20px;
  }
}

@media(max-width: 991px){
  .carScore{
    text-align: right;
  }

  .carScoreLabel{
    width: 100%;
    justify-content: flex-end;
  }

  .detailsContent{
    flex-direction: row;
    justify-content: space-between;
    
  }

.statusSection{
  flex-direction: row;
  gap: 10px;
}

.statusItem{
  min-width: 24%;
}

.damageStatus{
  min-width: 49%;
}

.rightColumn{
  width: 100% !important;
}

.container{
  padding: 20px 15px;
}

}

@media(max-width: 800px){
  .statusSection{
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
  }

  .detailsList{
    font-size: 16px;
    margin-top: 10px;
  }

  .locationAddress{
    font-size: 16px;
  }

  .damageStatus{
    font-size: 16px;
  }

  .damageIcon{
    width: 16px;
  }

  .infoCard{
    margin-top: 10px;
  }

  .locationAddress{
    padding: 10px;
  }

  .vehicleDetails{
    padding: 10px;
  }
}

@media (max-width: 600px) {

  .valuationPrice input{
    font-size: 18px;
  }

  .detailsValue input{
    font-size: 12px;
    padding: 5px;
  }


  .container {
    padding: 24px 0 0;
  }

  .infoCard {
    margin-top: 0;
    padding: 10px;
    border-radius: 5px;
  }

  .valuationPriceWrapper,
  .carScoreWrapper {
    margin-top: 0px;
  }

  .valuationSection {
    padding: 10px 0;
  }

  .valuationPriceLabel {
    font-size: 12px;
    font-weight: 400;
  }

  .icon {
    width: 8px;
  }

  .valuationPrice,
  .carScore {
    margin-top: 10px;
    font-size: 18px;
  }

  .valuationContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .carScoreLabel {
    font-size: 12px;
    font-weight: 400;
    width: 85px;
  }

  .carScoreIcon {
    width: 8px;
  }

  .carScore {
    width: 82px;
    text-align: left;
  }

  .carScoreWrapper {
    align-items: flex-start;
    width: 85px;
  }

  .carScoreColumn {
    width: 85px;
  }

  .valuationSection {
    border-top: 2px solid #f7f8ff;
    border-bottom: 2px solid #f7f8ff;
  }

  .inspectionLocation{
    margin-top: 10px;
  }

  .locationHeader{
    font-size: 14px;
    font-weight: 700;
  }

  .locationAddress{
    padding: 10px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }

  .vehicleDetails{
    margin-top: 10px;
    padding: 10px;
  }

  .detailsList{
    margin-top: 0;
    font-size: 12px;
    font-weight: 400;
  }

  .detailsContent{
    flex-direction: row;
  }

  .statusSection{
    margin-top: 10px;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-between;
  }

  .statusItem{
    height: 23px;
    padding: 5px;
    border-radius: 3px;
  }

  .damageStatus{
    padding: 5px;
    font-size: 12px;
    border-radius: 3px;
  }

  .damageIcon{
    width: 10px;
  }

  .imageGallery{
    margin-top: 24px;
    padding: 10px;
    border-radius: 5px;
  }

  .rightColumn{
    margin-left: 0;
  }

  .imageCard{
    padding: 5px;
  }

  .imageTitle{
    font-size: 12px;
  }

  .approvalIcon{
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  .imageMetadata{
    font-size: 12px;
    font-weight: 400;
  }

  .metadataIcon{
    width: 10px;
    height: 10px;
  }

  .leftArrow, .rightArrow{
    width: 20px;
    height: 20px;
  }

  .sliderArrows{
    gap: 10px;
    bottom: 10px;
    right: 15px;
  }

  .locationIcon{
    width: 8px;
  }

  .locationHeader{
    gap: 2px;
  }

  .damageStatus{
    height: auto;
    min-width: auto;
  }

  .statusItem{
    min-width: auto;
  }
}
